<template>
  <v-container class="px-md-10" v-if="productDescription">
    <h3 class="default--text">
      {{ productDescription.NOME_GRUPPO }}
    </h3>
    <v-expansion-panels v-model="panel" flat multiple>
      <v-expansion-panel
        v-for="(item, index) in productInformations"
        :key="index"
      >
        <v-expansion-panel-header disable-icon-rotate>
          <template v-slot:actions>
            <v-icon class="font-weight-bold" color="default">
              {{ panel.includes(index) ? "$minus" : "$plus" }}</v-icon
            >
          </template>
          <h4>{{ item.NOME_DATO }}</h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-if="isNutritionalData(item.NOME_DATO)">
            <table class="w-100">
              <tbody>
                <tr
                  v-for="(nutriItem, index) in item.VALORI.ROW.filter(
                    row => row.CODICE_NOME === 'N' && row.NOME
                  )"
                  :key="index"
                  :style="{
                    backgroundColor: index % 2 === 0 ? '#ffffff' : '#F3F3F3'
                  }"
                >
                  <td class="pa-2" v-if="index === 0">
                    {{ nutriItem.CODICE_VALORE }}: {{ nutriItem.NOME }}
                  </td>
                  <td class="pa-2" v-else>{{ nutriItem.NOME }}</td>
                  <td class="pa-2 text-right">{{ nutriItem.VALORE }}</td>
                </tr>
              </tbody>
            </table>
          </template>

          <template v-else-if="allergies(item.NOME_DATO)">
            <template v-if="allergies(item.NOME_DATO)">
              <span v-if="item.VALORI.ROW">
                Contiene:{{
                  item?.VALORI?.ROW?.map(nutriItem => nutriItem.NOME).join(", ")
                }}
              </span>
            </template>
          </template>
          <template v-else>
            <div
              class="d-flex"
              v-for="(child, index) in item.VALORI"
              :key="index"
            >
              <span v-if="child?.CODICE_VALORE">{{
                child?.CODICE_VALORE
              }}</span>
              <span v-if="child?.NOME" class="font-weight-bold mr-2">
                {{ child?.NOME }}:</span
              >
              <span v-if="child?.VALORE">{{ child.VALORE }}:</span>

              <p v-if="child?.TESTO">{{ child.TESTO }}</p>
            </div>
          </template>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<style lang="scss">
table tr td {
  padding: 10px;
}
</style>
<script>
export default {
  name: "ProductDescription",
  data() {
    return {
      panel: [0],
      disabled: false,
      readonly: false
    };
  },
  props: {
    productDescription: {
      type: Object,
      required: false
    }
  },
  computed: {
    productInformations() {
      return this.productDescription?.DATI?.ROW;
    }
  },
  methods: {
    isNutritionalData(name) {
      return (
        name.toLowerCase() === "informazioni nutrizionali" ||
        name.toLowerCase() === "informazioni nutrizionali calcolate"
      );
    },
    allergies(name) {
      return name == "Allergeni" || name == "allergeni";
    }
  }
};
</script>
